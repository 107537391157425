<template>

  <div class="language-menu">

    <div class="language-menu__button" @click="menuOpen = !menuOpen">
      <div class="language-menu__active">
        <div class="language-menu__flag">
          <img alt="flag" width="22" height="22" :src="require('@/assets/img/flags/' + languageFlag + '.svg')" />
        </div>
      </div>
      <div class="language-menu__drop-down-icon"></div>
    </div>

    <div class="language-menu__menu" v-if="menuOpen">
      <ul class="language-menu__list">
        <template v-for="languageOption in languageOptions">
          <li class="language-menu__item" :key="languageOption" @click="setLanguage(languageOption)">

            <span class="language-menu__flag  language-menu__flag&#45;&#45;small">
                <img alt="flag" width="22" height="22" :src="require('@/assets/img/flags/' + languageOption + '.svg')" />
            </span>
            <span class="language-menu__label">
              {{ getLanguageName(languageOption) }}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: "LanguageMenu",

  data: function() {
    return {
      menuOpen: false
    };
  },

  methods: {
    getLanguageName(language) {
      switch (language) {
        case 'en_GB':
          return 'English';

        case 'de':
          return 'Deutsch';

        case 'fr':
          return 'Français';

        case 'nl':
          return 'Nederlands';

        case 'es':
          return 'Español';

        case 'it':
          return 'Italiano';

        case 'pt':
          return 'Português';
        case 'pt_BR':
          return 'Brazil';

        default:
          return language
      }
    },

    setLanguage(language) {
      localStorage.setItem('language', language);
      window.location.reload()
    }
  },

  computed: {
    languageFlag() {

      const language =  this.$i18n.locale
      switch (language) {
        case 'en-US':
          return 'en_GB'

        default:
          return language
      }
    },

    languageOptions() {
      return Object.keys(this.$i18n.messages)
    }
  }
}
</script>