<template>
  
  <div class="provider-page is-open">
    <div class="provider-page__content">
      <template>
        <d-row lg="12">
          <d-col>
            <div class="statistics__label">{{ $t('order_table.column_title.date') }}</div>
            <div class="d-flex rounded shadow-sm" style="width:fit-content;">
              <d-form-select class="bg-white border-none" style="border-radius:0; box-shadow:none; outline:none; width: fit-content; background-color: transparent; border: none;" @change="renderCharts($event,'Regular'); renderCharts($event,'Covered');" v-model="regularCurrentMonth" :options="monthOptions" />
              <input class="bg-white" style="outline:none; max-width: 4rem; background-color: transparent; border: none;" type="number"  v-model="yearValue"  @change="renderCharts(regularCurrentMonth,'Regular'); renderCharts(regularCurrentMonth,'Covered');">
            </div>
          </d-col>
          <d-col class="col-lg-12 mt-3">
            {{$t('statistics.Taken_spot')}}
            <div class="d-flex align-items-center mt-1">
              <span class="mr-2 ml-2" style="width: 30px; height:12px; background-color: #3492eb"></span> 0% - 70%
              <span class="mr-2 ml-2" style="width: 30px; height:12px; background-color: #00cc00"></span> 71% - 80%
              <span class="mr-2 ml-2" style="width: 30px; height:12px; background-color: #ebe534"></span> 81% - 90%
              <span class="mr-2 ml-2" style="width: 30px; height:12px; background-color: #ff8533"></span> 91% - 99%
              <span class="mr-2 ml-2" style="width: 30px; height:12px; background-color: #ff1a1a"></span> 100% ({{$t('statistics.full')}})
            </div>
          </d-col>
          <d-col class="col-lg-12">
            <div class="provider-page__content-area">
            <h3 class="provider-page__title">{{$t('priceList.uncovered')}} </h3>
            <div v-if="regularService">
            <div class="statistics__ranger justify-content-start">
            </div>
            <div class="statistics__chart">

              <canvas id="RegularChart"></canvas>
            </div>
         
          </div>
          <div v-else>
            N/A
          </div>
          </div>
        </d-col>
        
        <d-col class="col-lg-12">
          <div class="provider-page__content-area mt-5">
            <h3 class="provider-page__title">{{$t('priceList.covered')}} </h3>
            <div v-if="coveredService">
           
            <div class="statistics__chart">
              
                  <canvas id="CoveredChart"></canvas>
            </div>
          
          </div>
          <div v-else>
            N/A
          </div>
          </div>
        </d-col>
      </d-row>
      </template>
    </div>


  </div>

</template>

<script>

  import Vue from "vue";
  import Chart from 'chart.js/auto';
  import moment from 'moment';


  const date = new Date()
  export default {
    name: "ServiceLocation",
    computed: {
    coveredService() {
      var tempServices = this.serviceLocation.services.filter((service) => service.covered);
      if (tempServices[0] ===  undefined){
        return null
      }      
      return tempServices[0]
    },
    regularService() {
      var tempServices = this.serviceLocation.services.filter((service) => !service.covered);

      if (tempServices[0] ===  undefined){
        return null
      }

      return tempServices[0]
    },
  },

    props: {
      serviceLocation: {
        type: Object,
      },
      tabID: String,
      startDate:Date,
      endDate:Date,
      labels: Array
    },

    data: function () {
      return {
        bus: new Vue(),
        canInteract: true,
        editableModel: null,
        serviceLocationData :'',
        displayChart:false,
        regularCurrentMonth: date.getMonth(),
        coveredCurrentMonth: date.getMonth(),
        currentYear: date.getFullYear(),
        yearValue: date.getFullYear(),
        typeID :'',
        chartData:'',
        serviceType:'Regular',
        loading:true,
        monthOptions: [
        {
          text:  this.$t('statistics.January') ,
          value: "0"
        },
        {
          text:  this.$t('statistics.February'),
          value: "1"
        },
        {
          text:  this.$t('statistics.March'),
          value: "2"
        },
        {
          text: this.$t('statistics.April'),
          value: "3"
        },
        {
          text: this.$t('statistics.May'),
          value: "4"
        },
        {
          text: this.$t('statistics.June'),
          value: "5"
        },
        {
          text: this.$t('statistics.July'),
          value:"6"
        },
        {
          text: this.$t('statistics.August'),
          value: "7"
        },
        {
          text: this.$t('statistics.September'),
          value: "8"
        },
        {
          text: this.$t('statistics.October'),
          value: "9"
        },
        {
          text: this.$t('statistics.November'),
          value: "10"
        },
        {
          text: this.$t('statistics.December'),
          value: "11"
        }

      ],
      }
    },

    beforeDestroy(){
      this.reloadPricings()
    },
    methods: {


      reloadPricings : async function(serviceType){
        var mutation = require("../graphql/pricelist/GetPricelist.gql")
        let serviceId 


        if(serviceType=='Regular' && this.regularService){
          serviceId = this.regularService.id
        }else if(this.coveredService){
          serviceId = this.coveredService.id
        }
       
        var variables = {
          id : serviceId
        }
        const data = await this.$apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'})
          .then(data => {
            try{
   
              if(serviceType=='Regular'){
                this.regularService.pricelists = data.data.service.pricelists
              }else{
                this.coveredService.pricelists = data.data.service.pricelists
              }
              
              this.$refs.RegularPricingComponent.extractDurations( this.regularService.pricelists)
              this.$refs.CoveredPricingComponent.extractDurations( this.coveredService.pricelists)

              }catch(e){
              console.log(e);
            }
          })
      },

    ////
    renderCharts: async function (month,type) {     
     
      const fistDay = new Date(this.yearValue, parseInt(month) );
      const lastDay = new Date(this.yearValue, parseInt(month) + 1, 0);
      const startDate = moment(fistDay).format("YYYY-MM-DD")
      const endDate = moment(lastDay).format("YYYY-MM-DD")

     let labelData =  []
     
     for (var index = 1; index <= moment(lastDay).format("DD"); index++) {
      let monthValue
       if(month<9){

        monthValue = '0' + (parseInt(month)+1)

       }else{
        monthValue = (parseInt(month)+1)
       }

          labelData.push(this.yearValue+'/'+ monthValue +'/'+index)
      }
    
      const query = require("../graphql/GetServiceByID.gql")
      let serviceId 
      let chartName
    
        if(type=='Regular'){
          serviceId = this.regularService.id
          chartName = "RegularChart"
        }else{
          serviceId = this.coveredService.id
          chartName = "CoveredChart"
        }
  
      const variables = {
        id :serviceId,
        start: startDate,
        end: endDate
      }

      
    await this.$apollo.query({query, variables, fetchPolicy: 'no-cache'})
    .then(data => {
      try{
        
        this.chartData = data.data.service
        this.loading = false

        }catch(e){
        console.log(e);
      }
            })
      const defaultChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        }
      }
      let chartStatus = Chart.getChart(chartName); // <canvas> id
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

        let colors = []

        this.chartData.capacityCounter.map(item=>{

          let percenrage = Math.ceil((item / this.chartData.parking_capacity) *100) 

          if(percenrage <=70){
            colors.push('#1E88E5') //blue
          } else if(percenrage <=80){
            colors.push('#00cc00') //green
          } else if(percenrage <=90 ){
            colors.push('#FFC107') //yellow
          } else if(percenrage <= 99){
            colors.push('#ff8533') //orange
          } else if(percenrage >= 100){
            colors.push('#ff1a1a') //red
          }
  
        })
   
          this.chartOrderTotal = new Chart(chartName, {
            type: 'bar',
            data: {
              labels:labelData,
              datasets: [{
                data: this.chartData.capacityCounter,
                fill: false,
                label:this.$t('statistics.Taken_spot'),
                backgroundColor: colors,
              }]
            },
            options: {
              ...defaultChartOptions, ...{
                scales: {
                  y: {
                    beginAtZero: true,
                     min: 0,
                     max:  this.chartData.parking_capacity,
                    ticks: {
                      stepSize: 10,
                   
                    },
                  }
                }
              },
            }
          });

      },
    ///

    },

    mounted() {
    if(this.serviceLocation){
      this.serviceLocationData = this.serviceLocation
      this.typeID = this.serviceLocation.parking_type_id
      this.renderCharts(this.coveredCurrentMonth,'Covered')
      this.renderCharts(this.regularCurrentMonth,'Regular')
    }


    },

    destroyed() {
        this.editableModel = null
    }
  }
</script>
